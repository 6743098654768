const encodeState = obj => {
  try {
    const stateObject = Object.assign(
      {},
      obj,
      {
        timestamp: Date.now()
      }
    );

    return window.btoa(JSON.stringify(stateObject));
  } catch (e) {
    console.error(e);
    return '';
  }
};

export {
  encodeState
};
